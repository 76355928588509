



















import Vue from 'vue'
import { api } from '@/utils/api'

import JSONEditor from '@/components/knitiv/json-editor.vue'

export default Vue.extend({
  name: 'Oo',

  components: {
    JSONEditor,
  },
  data() {
    return {
      data: '',
      kid: '',
    }
  },

  async mounted() {
    this.kid = this.$route.params.id

    const response = await api().objectInfo({
      kid: this.kid,
      get: ['ALL'],
    })
    this.data = JSON.parse(response.kjson)
  },
  methods: {
    save() {
      api().objectSet({
        items: [{
          target_objnum: this.kid,
          OBJECT_LIST: {
            [this.kid]: {
              kjson: JSON.stringify(this.data),
            },
          },
        }],
      })
    },
  },
})
