







import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import Editor, { JSONEditorMode } from 'jsoneditor'

@Component
export default class JSONEditor extends Vue {
  @Prop({
    type: [Number, String],
    default: '100%',
    required: false,
  })
  width!: number | string

  @Prop({
    type: [Number, String],
    default: '150px',
    required: false,
  })
  height!: number | string

  @Prop({
    type: String,
    default: 'tree',
    required: false,
  })
  mode!: JSONEditorMode

  @Prop({ type: [Object, Array], required: false, default: () => ({}) })
  value!: any | any[]

  @Watch('value', { deep: true, immediate: true })
  onValueChanged(newValue: any) {
    if (this.editor) {
      this.editor.update(newValue)
    }
  }

  @Watch('mode', { immediate: true })
  OnModeChanged(mode: JSONEditorMode) {
    if (this.editor && this.editor.setMode) {
      try {
        this.editor?.setMode(mode)
      } catch (e) {
        console.error('invalid mode', e)
      }
    }
  }

  $refs!: {
    editor: HTMLElement;
  }

  editor: Editor | null = null

  get editorStyle() {
    return {
      height: this.height,
      width: this.width,
    }
  }

  onChange() {
    try {
      const value = this.editor?.get()
      this.$emit('input', value)
    } catch (e) {
      console.error('oops', e)
    }
  }

  mounted() {
    // create the editor
    const container = this.$refs.editor

    const options = {
      search: true,
      modes: ['tree', 'view', 'form', 'code', 'text', 'preview'],
      onChange: this.onChange,
    }

    // @ts-ignore
    this.editor = new Editor(container, options)
    if (this.editor) {
      this.editor.set(this.value)
      this.editor.setMode(this.mode)
    }
  }
}
